import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';

import {
  parse,
} from 'date-fns';


import {
  CalendarEvent,
  CalendarView,
} from 'angular-calendar';

import { ActivatedRoute } from '@angular/router';

// material components
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// interfaces
import { Patient } from 'src/app/interfaces/patient.interface';
import { Appointment } from 'src/app/interfaces/appointment.interface';

// services
import FirestoreService from 'src/app/services/firestore.service';
import PatientsService from 'src/app/services/patients.service';

// components
import { AppointmentListDialogComponent } from 'src/app/components/appointment-list-dialog/appointment-list-dialog.component';
import { AddAppointmentComponent } from 'src/app/components/add-appointment/add-appointment.component';
import { AddVitalComponent } from 'src/app/components/add-vital/add-vital.component';
import { AddInvestigationComponent } from 'src/app/components/add-investigation/add-investigation.component';
import { AddComplaintComponent } from 'src/app/components/add-complaint/add-complaint.component';

// forms
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-view-patient',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './view-patient.component.html',
  styleUrls: ['./view-patient.component.scss']
})
export class ViewPatientComponent implements OnInit {
  currentPatientId: string;
  currentPatientForm: FormGroup;
  appointments: Appointment[] = [];

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  events: CalendarEvent[] = [];

  constructor(
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private patientsService: PatientsService,
    private _snackbar: MatSnackBar
  ) {
    this.currentPatientForm = new FormGroup(
      {
        id: new FormControl(''),
        firstName: new FormControl(''),
        lastName: new FormControl(''),
        address: new FormControl(''),
        age: new FormControl(''),
        occupation: new FormControl(''),
        vitals: new FormControl([]),
        allergies: new FormControl(''),
        complaints: new FormControl([]),
        appointments: new FormControl([]),
        investigations: new FormControl([]),
        dateRegistered: new FormControl(''),
      }
    );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.currentPatientId = params['id'];
      this.patientsService.getById(this.currentPatientId).subscribe((currentPatient) => {

        const {
          id,
          firstName,
          lastName,
          address,
          age,
          occupation,
          vitals,
          allergies,
          complaints,
          appointments,
          investigations,
          dateRegistered
        } = currentPatient;

        this.currentPatientForm.patchValue({
          id: this.currentPatientId,
          firstName,
          lastName,
          address,
          age,
          occupation,
          allergies,
          dateRegistered
        });

        this.currentPatientForm.controls.vitals.patchValue(vitals);
        this.currentPatientForm.controls.investigations.patchValue(investigations);
        this.currentPatientForm.controls.complaints.patchValue(complaints);
        this.currentPatientForm.controls.appointments.patchValue(appointments);

        this.appointments = appointments;
        this.events = this.appointments.map(({ id, date, description, time, entryDate }) => {
          return {
            id,
            date,
            time,
            entryDate,
            description,
            start: parse(date, 'dd/MM/yyyy', new Date()),
            title: `${time} - ${description}`,
          };
        });
      });
    });
  }

  updatePatientDetails(): void {
    const updatedPatient: Patient = {
      id: this.currentPatientForm.controls.id.value,
      firstName: this.currentPatientForm.controls.firstName.value,
      lastName: this.currentPatientForm.controls.lastName.value,
      address: this.currentPatientForm.controls.address.value,
      age: this.currentPatientForm.controls.age.value,
      occupation: this.currentPatientForm.controls.occupation.value,
      vitals: this.currentPatientForm.controls.vitals.value,
      allergies: this.currentPatientForm.controls.allergies.value,
      complaints: this.currentPatientForm.controls.complaints.value,
      investigations: this.currentPatientForm.controls.investigations.value,
      appointments: this.currentPatientForm.controls.appointments.value,
      dateRegistered: this.currentPatientForm.controls.dateRegistered.value
    };

    this.patientsService.update(updatedPatient);
    this.openSnackBar('Patient details have been updated');
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: this.currentPatientId,
      events: events
    };

    dialogConfig.minWidth = 325;
    this.dialog.open(AppointmentListDialogComponent, dialogConfig);
  }

  addAppointment(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: this.currentPatientId,
    }
    dialogConfig.minWidth = 300;
    this.dialog.open(AddAppointmentComponent, dialogConfig);
  }

  addVital(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: this.currentPatientId,
    }
    dialogConfig.minWidth = 350;
    this.dialog.open(AddVitalComponent, dialogConfig);
  }

  addInvestigation(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: this.currentPatientId,
    }
    dialogConfig.minWidth = 300;
    this.dialog.open(AddInvestigationComponent, dialogConfig);
  }

  addComplaint(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      id: this.currentPatientId,
    }
    dialogConfig.minWidth = 300;
    this.dialog.open(AddComplaintComponent, dialogConfig);
  }

  openSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', { duration: 2000 });
  }
}
