<mat-card [formGroup]="newVitalForm">
  <mat-card-header>
    <mat-card-title>New Vital</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list [cols]="1" [rowHeight]="'80px'" [gutterSize]="1">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Temperature</mat-label>
          <input matInput formControlName="temperature" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>BP Pulse</mat-label>
          <input matInput formControlName="bpPulse" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>SP02</mat-label>
          <input matInput formControlName="SP02" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Weight</mat-label>
          <input matInput formControlName="weight" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Height</mat-label>
          <input matInput formControlName="height" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>BMI</mat-label>
          <input matInput formControlName="BMI" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <button mat-raised-button color="primary" (click)="addVital()">
          ADD
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
