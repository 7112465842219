import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// components
import { AllPatientsComponent } from './pages/all-patients/all-patients.component';
import { NewPatientComponent } from './pages/new-patient/new-patient.component';
import { AllMedicinesComponent } from './pages/all-medicines/all-medicines.component';
import { ImportMedicineComponent } from './pages/import-medicine/import-medicine.component';
import { LoginComponent } from './pages/login/login.component';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { ViewPatientComponent } from './pages/view-patient/view-patient.component';
// firebase
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToAllPatients = () => redirectLoggedInTo(['all-patients']);

const routes: Routes = [
  { path: 'view-patient', component: ViewPatientComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, },
  { path: 'appointments', component: AppointmentComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, },
  { path: 'login', component: LoginComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToAllPatients }, },
  { path: 'all-patients', component: AllPatientsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, },
  { path: 'new-patient', component: NewPatientComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, },
  { path: 'all-medicines', component: AllMedicinesComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, },
  { path: 'import-medicine', component: ImportMedicineComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})


export class AppRoutingModule { }
