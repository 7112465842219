import { Component, OnInit } from '@angular/core';
import FirestoreService from '../../services/firestore.service';
import { Patient } from '../../interfaces/patient.interface';
import { allPatients } from 'src/app/services/data';
import { Router } from '@angular/router';

// services
import PatientsService from 'src/app/services/patients.service';

@Component({
  selector: 'app-all-patients',
  templateUrl: './all-patients.component.html',
  styleUrls: ['./all-patients.component.scss']
})
export class AllPatientsComponent implements OnInit {

  allPatients: Patient[];
  columns = [
    { prop: 'firstName', label: 'First Name' },
    { prop: 'lastName', label: 'Last Name' },
    { prop: 'address', label: 'Address' },
    { prop: 'age', label: 'Age' },
    { prop: 'occupation', label: 'Occupation' },
  ]

  constructor(public firestoreService: FirestoreService, public router: Router, private patientsService: PatientsService) { }

  ngOnInit(): void {
    this.patientsService.getAll().subscribe((data) => {
      this.allPatients = data;
    });
  }

  onActivate(event) {
    if (event.type == 'click') {
      const { row: { id } } = event;
      this.router.navigate(['/view-patient'], { queryParams: { id } });
    }
  }

}
