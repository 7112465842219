import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import FirestoreService from './services/firestore.service';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  isCollapsed = false;
  currentRoute: string;

  constructor(public router: Router, public firestoreService: FirestoreService) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => this.currentRoute = this.urlToLabel(event['urlAfterRedirects']));
  }

  urlToLabel(url: string): string {
    const urlToLabelMappings = {
      "/appointments": "All Appointments",
      "/login": "Login",
      "/all-patients": "All Patients",
      "/new-patient": "New Patient",
      "/all-medicines": "All Medicines",
      "/import-medicine": "Import Medicine",
    };

    return urlToLabelMappings[url];
  }

  async logOutHandler() {
    await this.firestoreService.signOut();
    this.router.navigate(['/login']);
  }
}

