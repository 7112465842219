import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Patient } from 'src/app/interfaces/patient.interface';
import PatientsService from 'src/app/services/patients.service';
import { Vital } from 'src/app/interfaces/vital.interface';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-new-patient',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.scss']
})
export class NewPatientComponent implements OnInit {

  newPatient: Patient = {
    id: '',
    firstName: '',
    lastName: '',
    age: '',
    address: '',
    occupation: '',
    allergies: [],
    vitals: [],
    investigations: [],
    complaints: [],
    appointments: [],
    dateRegistered: '',
  };
  constructor(private firestore: AngularFirestore, private patientsService: PatientsService, private _snackbar: MatSnackBar) { }

  ngOnInit(): void {
  }

  savePatient(): void {
    this.patientsService.create(this.newPatient);
    this.clearFields();
    this.openSnackBar('New Patient has been added!');
  }

  clearFields(): void {
    this.newPatient = {
      id: '',
      firstName: '',
      lastName: '',
      age: '',
      address: '',
      occupation: '',
      allergies: [],
      vitals: [],
      investigations: [],
      complaints: [],
      appointments: [],
      dateRegistered: '',
    };
  }

  openSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', { duration: 2000 });
  }

}
