import { Component, Inject, OnInit } from '@angular/core';

// material
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// calendar
import { CalendarEvent } from 'angular-calendar';

// services
import PatientsService from 'src/app/services/patients.service';

// interfaces
import { Appointment } from 'src/app/interfaces/appointment.interface';

@Component({
  selector: 'app-appointment-list-dialog',
  templateUrl: './appointment-list-dialog.component.html',
  styleUrls: ['./appointment-list-dialog.component.scss']
})
export class AppointmentListDialogComponent implements OnInit {

  events: CalendarEvent[];
  currentPatientId: string;

  constructor(
    private dialogRef: MatDialogRef<AppointmentListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private patientsService: PatientsService,
    private _snackbar: MatSnackBar,
  ) {

    const { id } = data;
    this.currentPatientId = id;
    this.events = data.events;

  }

  ngOnInit(): void {
  }

  deleteAppointment(appointmentId: string): void {
    const { id, description, time, date, entryDate, patientId } = this.events.find(({ id }) => id === appointmentId) as any;
    const appointment: Appointment = {
      id: id,
      description,
      time,
      date,
      entryDate,
    };

    this.patientsService.deleteAppointment(appointment, patientId);
    this.dialogRef.close();
    this.openSnackBar('Appointment removed!');
  }

  openSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', { duration: 2000 });
  }

}
