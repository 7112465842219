import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Medication } from 'src/app/interfaces/medication.interface';

// services
import PatientsService from 'src/app/services/patients.service';



@Component({
  selector: 'app-all-medicines',
  templateUrl: './all-medicines.component.html',
  styleUrls: ['./all-medicines.component.scss']
})
export class AllMedicinesComponent implements OnInit {
  displayedColumns: string[] = ['description', 'category', 'composition', 'retail_price', 'wholesale_price'];
  MEDICATION_DATA: Medication[] = [];
  dataSource: MatTableDataSource<Medication>;
  isLoading: boolean = true;


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(private patientsService: PatientsService) {
    patientsService.getAllMedicines().subscribe((medicines) => {
      this.dataSource = new MatTableDataSource(medicines);
      this.isLoading = false;
    });

  }

  ngOnInit(): void {
  }

}
