<mat-card>
  <mat-card-header>
    <mat-card-title>Login</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list [cols]="3" [rowHeight]="'400px'">
      <mat-grid-tile [colspan]="1" [rowspan]="1"></mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input
              [disabled]="disabled"
              matInput
              type="text"
              [value]="email"
              maxlength="20"
              placeholder="example@mail.com"
              (input)="email = $event.target.value"
            />
            <mat-icon matSuffix>email</mat-icon>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input
              [disabled]="disabled"
              matInput
              type="password"
              [value]="password"
              maxlength="20"
              (input)="password = $event.target.value"
            />
            <mat-icon matSuffix>password</mat-icon>
          </mat-form-field>
        </p>
        <p>
          <button
            color="primary"
            [disabled]="disabled"
            mat-raised-button
            (click)="login()"
          >
            Login
          </button>
        </p>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1"></mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
