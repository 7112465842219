<div>
  <ngx-datatable
    class="material"
    [rows]="allPatients"
    [columns]="columns"
    [rowHeight]="50"
    [headerHeight]="50"
    [footerHeight]="50"
    (activate)="onActivate($event)"
  >
  </ngx-datatable>
</div>
