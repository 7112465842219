<div class="example-container mat-elevation-z8">
  <div class="example-loading-shade" *ngIf="isLoading">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
  </div>
  <div class="example-table-container">
    <mat-card>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Ex. ium"
          #input
        />
      </mat-form-field>
    </mat-card>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td mat-cell *matCellDef="let element">{{ element.category }}</td>
      </ng-container>

      <ng-container matColumnDef="composition">
        <th mat-header-cell *matHeaderCellDef>Composition</th>
        <td mat-cell *matCellDef="let element">{{ element.composition }}</td>
      </ng-container>

      <ng-container matColumnDef="retail_price">
        <th mat-header-cell *matHeaderCellDef>Retail</th>
        <td mat-cell *matCellDef="let element">{{ element.retail_price }}</td>
      </ng-container>

      <ng-container matColumnDef="wholesale_price">
        <th mat-header-cell *matHeaderCellDef>Wholesale</th>
        <td mat-cell *matCellDef="let element">
          {{ element.wholesale_price }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          No data matching the filter "{{ input.value }}"
        </td>
      </tr>
    </table>
  </div>
</div>
