import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

// material components
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';

// components
import { AllPatientsComponent } from './pages/all-patients/all-patients.component';
import { NewPatientComponent } from './pages/new-patient/new-patient.component';
import { AllMedicinesComponent } from './pages/all-medicines/all-medicines.component';
import { ImportMedicineComponent } from './pages/import-medicine/import-medicine.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { LoginComponent } from './pages/login/login.component';
import { environment } from 'src/environments/environment';

// firebase
import { AngularFireModule } from '@angular/fire';

// firebase-ui
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';

// services
import FirestoreService from './services/firestore.service';
import PatientsService from './services/patients.service';
import { AppointmentComponent } from './pages/appointment/appointment.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ViewPatientComponent } from './pages/view-patient/view-patient.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { AppointmentListDialogComponent } from './components/appointment-list-dialog/appointment-list-dialog.component';

// clarity components
import { ClarityModule } from '@clr/angular';
import { AddAppointmentComponent } from './components/add-appointment/add-appointment.component';
import { AddVitalComponent } from './components/add-vital/add-vital.component';
import { AddInvestigationComponent } from './components/add-investigation/add-investigation.component';
import { AddComplaintComponent } from './components/add-complaint/add-complaint.component';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    AllPatientsComponent,
    NewPatientComponent,
    AllMedicinesComponent,
    ImportMedicineComponent,
    PageNotFoundComponent,
    SideBarComponent,
    LoginComponent,
    AppointmentComponent,
    ViewPatientComponent,
    AppointmentListDialogComponent,
    AddAppointmentComponent,
    AddVitalComponent,
    AddInvestigationComponent,
    AddComplaintComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatGridListModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatMenuModule,
    MatCardModule,
    MatTableModule,
    MatDatepickerModule,
    MatDialogModule,
    //Clarity
    ClarityModule,
    //Firebase
    AngularFireModule.initializeApp({ ...environment.firebaseConfig }),
    NgxAuthFirebaseUIModule.forRoot(environment.firebaseConfig),
    //ngx-datatable
    NgxDatatableModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
  ],
  providers: [FirestoreService, PatientsService],
  bootstrap: [AppComponent]
})
export class AppModule { }
