import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import FirestoreService from '../../services/firestore.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string = '';
  password: string = '';
  disabled: boolean = false;
  showSpinner: boolean = false;

  constructor(public router: Router, public auth: AngularFireAuth, public firestoreService: FirestoreService, private _snackbar: MatSnackBar) { }

  ngOnInit(): void {

  }

  async login() {
    this.disabled = true;
    this.showSpinner = true;
    let authResult = await this.firestoreService.signIn(this.email, this.password);
    if (authResult) {
      this.openSnackBar('Welcome back!');
      this.router.navigate(['/all-patients']);
    } else {
      this.email = '';
      this.password = '';
      this.openSnackBar('Invalid email/password');
    }
    this.disabled = false;
    this.showSpinner = false;
  }

  logout() {
    this.auth.signOut();
  }

  openSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', { duration: 2000 });
  }
}
