import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

//services
import PatientsService from 'src/app/services/patients.service';

// interfaces
import { Appointment } from 'src/app/interfaces/appointment.interface';

// material
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";


@Component({
  selector: 'app-add-appointment',
  templateUrl: './add-appointment.component.html',
  styleUrls: ['./add-appointment.component.scss']
})
export class AddAppointmentComponent implements OnInit {

  currentPatientId: String;
  newAppointmentForm: FormGroup;

  constructor(
    private patientsService: PatientsService,
    private dialogRef: MatDialogRef<AddAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _snackbar: MatSnackBar) {
    const { id } = data;
    this.currentPatientId = id;
    this.newAppointmentForm = new FormGroup({
      description: new FormControl(''),
      date: new FormControl(''),
      time: new FormControl(''),
    });
  }

  ngOnInit(): void {

  }

  addAppointment(): void {
    const description = this.newAppointmentForm.controls.description.value;
    const time = this.newAppointmentForm.controls.time.value;
    const date = moment(this.newAppointmentForm.controls.date.value).format('DD/MM/YYYY');
    const id = this.patientsService.firestoreService.createId();
    const entryDate = moment().format('DD/MM/YYYY');

    const newAppointment: Appointment = {
      id,
      description,
      time,
      date,
      entryDate
    };
    this.patientsService.addAppointment(newAppointment, this.currentPatientId);
    this.openSnackBar('New Appointment scheduled!');
    this.dialogRef.close('Appointment Scheduled!');
  }

  openSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', { duration: 2000 });
  }

}
