<mat-card [ngStyle]="{ 'border-radius': '0' }">
  <mat-card-header>
    <mat-card-title>
      New Patient
    </mat-card-title>
    <mat-card-subtitle>Patient Details</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list [cols]="3" [gutterSize]="2" [rowHeight]="'100px'">
      <mat-grid-tile [colspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput [(ngModel)]="newPatient.firstName" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput [(ngModel)]="newPatient.lastName" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Age</mat-label>
          <input matInput [(ngModel)]="newPatient.age" />
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list [cols]="3" [gutterSize]="2" [rowHeight]="'100px'">
      <mat-grid-tile [colspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Address</mat-label>
          <input matInput [(ngModel)]="newPatient.address" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Occupation</mat-label>
          <input matInput [(ngModel)]="newPatient.occupation" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Allergies</mat-label>
          <input matInput [(ngModel)]="newPatient.allergies" />
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list [cols]="6" [gutterSize]="1" [rowHeight]="'100px'">
      <mat-grid-tile [colspan]="1">
        <button
          mat-raised-button
          color="primary"
          (click)="savePatient()"
        >
          Add
        </button>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="5"></mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
