import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';

import {
  parse,
} from 'date-fns';


import {
  CalendarEvent,
  CalendarView,
} from 'angular-calendar';

import { ActivatedRoute } from '@angular/router';

// material components
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

// interfaces
import { Patient } from 'src/app/interfaces/patient.interface';
import { Appointment } from 'src/app/interfaces/appointment.interface';

// services
import PatientsService from 'src/app/services/patients.service';

// components
import { AppointmentListDialogComponent } from 'src/app/components/appointment-list-dialog/appointment-list-dialog.component';

// clarity
import { ClrTimeline } from '@clr/angular';

// rxjs
import { Subject } from 'rxjs';

@Component({
  selector: 'app-appointment',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  allPatients: Patient[];
  appointments: any[] = [];

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();

  constructor(public route: ActivatedRoute, public dialog: MatDialog, private patientsService: PatientsService) {
  }

  ngOnInit(): void {
    this.patientsService.getAll().subscribe((data) => {
      this.appointments = [];
      this.allPatients = data
      this.allPatients.map(({ id: patientId, appointments, firstName, lastName }) => {
        appointments.map(({ id, description, date, time, entryDate }) => {
          const appointment = {
            patientName: `${firstName} ${lastName}`,
            patientId,
            id,
            description,
            date,
            time,
            entryDate,
          };
          this.appointments.push(appointment);
        });
      });

      this.events = this.appointments.map(({ id, date, description, time, entryDate, patientId, patientName }) => {
        return {
          patientName,
          patientId,
          id,
          date,
          time,
          entryDate,
          description,
          start: parse(date, 'dd/MM/yyyy', new Date()),
          title: `${time} - ${description} - Mr(s) ${patientName}`,
        };
      });

      this.refresh.next();

    });
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      events: events
    };

    dialogConfig.minWidth = 500;
    this.dialog.open(AppointmentListDialogComponent, dialogConfig);
  }
}
