import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';

// interfaces
import { Patient } from '../interfaces/patient.interface';
import { Appointment } from '../interfaces/appointment.interface';
import { Vital } from '../interfaces/vital.interface';
import { Complaint } from '../interfaces/complaint.interface';
import { Investigation } from '../interfaces/investigation.interface';
import { Medication } from '../interfaces/medication.interface';

@Injectable({
  providedIn: 'root'
})
export default class PatientsService {

  constructor(public firestoreService: AngularFirestore) {

  }

  getAll(): Observable<Patient[]> {
    return this.firestoreService.collection('patients').valueChanges({ idField: 'id' }) as Observable<Patient[]>;
  }

  async create(patient: Patient): Promise<String> {
    const { id } = await this.firestoreService.collection('patients').add(patient);
    return id;
  }

  update(patient: Patient): void {
    this.firestoreService.doc(`patients/${patient.id}`).update(patient);
  }

  getById(id: String): Observable<Patient> {
    return this.firestoreService.doc(`patients/${id}`).valueChanges() as Observable<Patient>;
  }

  addAppointment(appointment: Appointment, patientId: String): void {
    const arrayUnion = firebase.default.firestore.FieldValue.arrayUnion;
    this.firestoreService.doc(`patients/${patientId}`).update({ appointments: arrayUnion(appointment) })
  }

  addVital(vital: Vital, patientId: String): void {
    const arrayUnion = firebase.default.firestore.FieldValue.arrayUnion;
    this.firestoreService.doc(`patients/${patientId}`).update({ vitals: arrayUnion(vital) })
  }

  addComplaint(complaint: Complaint, patientId: String): void {
    const arrayUnion = firebase.default.firestore.FieldValue.arrayUnion;
    this.firestoreService.doc(`patients/${patientId}`).update({ complaints: arrayUnion(complaint) })
  }

  addInvestigation(investigation: Investigation, patientId: String): void {
    const arrayUnion = firebase.default.firestore.FieldValue.arrayUnion;
    this.firestoreService.doc(`patients/${patientId}`).update({ investigations: arrayUnion(investigation) })
  }

  deleteAppointment(appointment: Appointment, patientId: string): void {
    const arrayRemove = firebase.default.firestore.FieldValue.arrayRemove;
    this.firestoreService.doc(`patients/${patientId}`).update({ appointments: arrayRemove(appointment) })
  }

  getAllMedicines(): Observable<Medication[]> {
    return this.firestoreService.collection('medicines').valueChanges() as Observable<Medication[]>;
  }
}
