<mat-list role="list">
  <div mat-subheader>Appointments</div>
  <div *ngIf="events.length == 0">
    No appointments on that date
  </div>
  <mat-list-item *ngFor="let event of events">
    <div mat-line>{{ event.title }}</div>
    <button mat-icon-button color="warn">
      <mat-icon mat-list-icon (click)="deleteAppointment(event.id)">
        delete
      </mat-icon>
    </button>
    <mat-divider *ngIf="events.length > 1"></mat-divider>
  </mat-list-item>
</mat-list>
