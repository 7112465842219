import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as moment from 'moment';

// material
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// interfaces
import { Complaint } from 'src/app/interfaces/complaint.interface';

// services
import PatientsService from 'src/app/services/patients.service';

@Component({
  selector: 'app-add-complaint',
  templateUrl: './add-complaint.component.html',
  styleUrls: ['./add-complaint.component.scss']
})
export class AddComplaintComponent implements OnInit {

  newComplaintForm: FormGroup;
  currentPatientId: String;

  constructor(
    private patientsService: PatientsService,
    private dialogRef: MatDialogRef<AddComplaintComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _snackbar: MatSnackBar,
  ) {
    const { id } = data;
    this.currentPatientId = id;
    this.newComplaintForm = new FormGroup({
      description: new FormControl(''),
      treatment: new FormControl(''),
    });
  }

  ngOnInit(): void {
  }

  addComplaint(): void {
    const newComplaint: Complaint = {
      description: this.newComplaintForm.controls.description.value,
      treatment: this.newComplaintForm.controls.treatment.value,
      date: moment().format('DD/MM/YYYY')
    }

    this.patientsService.addComplaint(newComplaint, this.currentPatientId);
    this.dialogRef.close();
    this.openSnackBar('New Complaint added!');
  }

  openSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', { duration: 2000 });
  }

}
