<mat-action-list role="list">
  <mat-list-item role="listitem" (click)="navigateTo('appointments')">
    <mat-icon mat-list-icon>calendar_today</mat-icon>
    <div mat-line>Appointments</div>
  </mat-list-item>
  <mat-list-item role="listitem" (click)="navigateTo('all-patients')">
    <mat-icon mat-list-icon>people</mat-icon>
    <div mat-line>All Patients</div>
  </mat-list-item>
  <mat-list-item role="listitem" (click)="navigateTo('new-patient')">
    <mat-icon mat-list-icon>person_add</mat-icon>
    <div mat-line>New Patient</div>
  </mat-list-item>
  <mat-list-item role="listitem" (click)="navigateTo('all-medicines')">
    <mat-icon mat-list-icon>medication</mat-icon>
    <div mat-line>All Medicines</div>
  </mat-list-item>
  <!-- <mat-list-item role="listitem" (click)="navigateTo('import-medicine')">
    <mat-icon mat-list-icon>import_export</mat-icon>
    <div mat-line>Import Medicine</div>
  </mat-list-item> -->
</mat-action-list>
