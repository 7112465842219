// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCpBGPLyHukfm84mVfNgXAA_b-fcHAbqfw",
    authDomain: "mokabinet-6ff36.firebaseapp.com",
    projectId: "mokabinet-6ff36",
    storageBucket: "mokabinet-6ff36.appspot.com",
    messagingSenderId: "263925717957",
    appId: "1:263925717957:web:4eaed3f5d94906072a09ac",
    measurementId: "G-EJNRV0T77K"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
