<mat-grid-list [cols]="1" [gutterSize]="1" [rowHeight]="'100px'">
  <mat-grid-tile [colspan]="1" [rowspan]="8">
    <mat-card>
      <mat-card-header>
        <mat-card-title>All Appointments</mat-card-title>
        <mat-card-subtitle>{{ viewDate | date: "MMMM" }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-row">
          <button
            mat-raised-button
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
          >
            Previous
          </button>
          <button mat-raised-button mwlCalendarToday [(viewDate)]="viewDate">
            Today
          </button>
          <button
            mat-raised-button
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
          >
            Next
          </button>
        </div>
        <br />
        <mwl-calendar-month-view
          (dayClicked)="dayClicked($event.day)"
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
        >
        </mwl-calendar-month-view>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
