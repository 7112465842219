import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

// moment
import * as moment from 'moment';

// interfaces
import { Investigation } from 'src/app/interfaces/investigation.interface';

//services
import PatientsService from 'src/app/services/patients.service';

// material
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-add-investigation',
  templateUrl: './add-investigation.component.html',
  styleUrls: ['./add-investigation.component.scss']
})
export class AddInvestigationComponent implements OnInit {

  newInvestigationForm: FormGroup;
  currentPatientId: String;

  constructor(
    private patientsService: PatientsService,
    private dialogRef: MatDialogRef<AddInvestigationComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _snackbar: MatSnackBar,
  ) {
    const { id } = data
    this.currentPatientId = id;
    this.newInvestigationForm = new FormGroup({
      bloodResults: new FormControl(''),
      xrayScan: new FormControl(''),
    });
  }

  ngOnInit(): void {
  }

  addInvestigation(): void {
    const newInvestigation: Investigation = {
      bloodResults: this.newInvestigationForm.controls.bloodResults.value,
      xrayScan: this.newInvestigationForm.controls.xrayScan.value,
      date: moment().format('DD/MM/YYYY')
    }

    this.patientsService.addInvestigation(newInvestigation, this.currentPatientId);
    this.openSnackBar('New Investigation Added!');
    this.dialogRef.close();

  }

  openSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', { duration: 2000 });
  }

}
