<mat-card [formGroup]="newComplaintForm">
  <mat-card-header>
    <mat-card-title>New Complaint</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-grid-list [cols]="1" [rowHeight]="'80px'" [gutterSize]="1">
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <input matInput formControlName="description" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <mat-form-field appearance="outline">
          <mat-label>Treatment</mat-label>
          <input matInput formControlName="treatment" />
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1">
        <button mat-raised-button color="primary" (click)="addComplaint()">
          ADD
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card-content>
</mat-card>
