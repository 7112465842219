import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Vital } from 'src/app/interfaces/vital.interface';
import * as moment from 'moment';

//material
import { MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// services
import PatientsService from 'src/app/services/patients.service';

@Component({
  selector: 'app-add-vital',
  templateUrl: './add-vital.component.html',
  styleUrls: ['./add-vital.component.scss']
})
export class AddVitalComponent implements OnInit {
  newVitalForm: FormGroup;
  currentPatientId: String;

  constructor(
    private patientsService: PatientsService,
    private dialogRef: MatDialogRef<AddVitalComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private _snackbar: MatSnackBar,
  ) {

    const { id } = data;
    this.currentPatientId = id;

    this.newVitalForm = new FormGroup({
      temperature: new FormControl(''),
      bpPulse: new FormControl(''),
      SP02: new FormControl(''),
      weight: new FormControl(''),
      height: new FormControl(''),
      BMI: new FormControl(''),
    });
  }

  ngOnInit(): void {
  }

  addVital(): void {
    const newVital: Vital = {
      temperature: this.newVitalForm.controls.temperature.value,
      bpPulse: this.newVitalForm.controls.bpPulse.value,
      SP02: this.newVitalForm.controls.SP02.value,
      weight: this.newVitalForm.controls.weight.value,
      height: this.newVitalForm.controls.height.value,
      BMI: this.newVitalForm.controls.BMI.value,
      date: moment().format('DD/MM/YYYYY'),
    }

    this.patientsService.addVital(newVital, this.currentPatientId);
    this.openSnackBar('New Vital added!');
    this.dialogRef.close("New Vital added!");
  }

  openSnackBar(message: string) {
    this._snackbar.open(message, 'Dismiss', { duration: 2000 });
  }

}
