<mat-grid-list cols="4" rowHeight="150px" [gutterSize]="5">
  <mat-grid-tile [colspan]="4" [rowspan]="1">
    <mat-toolbar
      [ngStyle]="{ 'background-color': '#3f51b5', color: 'white' }"
      class="mat-elevation-z10"
    >
      <mat-toolbar-row>
        <button mat-icon-button class="menu-icon" aria-label="menu icon">
          <mat-icon>local_pharmacy</mat-icon>
        </button>
        <span>Mokabinet</span>
        <span class="example-spacer"></span>
        <button
          mat-icon-button
          class="account-icon"
          aria-label="account icon"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="logOutHandler()">Log Out</button>
        </mat-menu>
      </mat-toolbar-row>
      <mat-toolbar-row
        class="mat-elevation-z8"
        [ngStyle]="{ 'background-color': '#3f51b5', color: 'white' }"
      >
        <span>{{ currentRoute | titlecase }}</span>
      </mat-toolbar-row>
    </mat-toolbar>
  </mat-grid-tile>
  <mat-grid-tile
    [colspan]="1"
    [rowspan]="6"
    [ngStyle]="{ 'border-right': '2px solid #e1e1e1' }"
  >
    <app-side-bar></app-side-bar>
  </mat-grid-tile>
  <mat-grid-tile
    [colspan]="3"
    [rowspan]="25"
    [ngStyle]="{ 'background-color': '#eeeeee' }"
  >
    <router-outlet></router-outlet>
  </mat-grid-tile>
</mat-grid-list>
