<mat-grid-list
  [cols]="1"
  [gutterSize]="5"
  [rowHeight]="400"
  [formGroup]="currentPatientForm"
>
  <mat-grid-tile [colspan]="1" [rowspan]="1" [ngStyle]="{ overflow: 'scroll' }">
    <!-- patient details -->
    <mat-card [ngStyle]="{ 'border-radius': '0' }">
      <mat-card-header>
        <mat-card-title>
          {{
            currentPatientForm.controls.firstName.value +
              " " +
              currentPatientForm.controls.lastName.value
          }}
        </mat-card-title>
        <mat-card-subtitle>Patient Details</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-grid-list [cols]="3" [gutterSize]="2" [rowHeight]="'100px'">
          <mat-grid-tile [colspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastName" />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>Age</mat-label>
              <input matInput formControlName="age" />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list [cols]="3" [gutterSize]="2" [rowHeight]="'100px'">
          <mat-grid-tile [colspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>Address</mat-label>
              <input matInput formControlName="address" />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>Occupation</mat-label>
              <input matInput formControlName="occupation" />
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <mat-form-field appearance="outline">
              <mat-label>Allergies</mat-label>
              <input matInput formControlName="allergies" />
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list [cols]="6" [gutterSize]="1" [rowHeight]="'100px'">
          <mat-grid-tile [colspan]="1">
            <button
              mat-raised-button
              color="primary"
              (click)="updatePatientDetails()"
            >
              Update
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="2">
            <button
              mat-raised-button
              color="primary"
              (click)="addAppointment()"
            >
              Add appointment
            </button>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="5"></mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="1" [rowspan]="1" [ngStyle]="{ overflow: 'scroll' }">
    <!-- vitals -->
    <mat-card [ngStyle]="{ 'border-radius': '0' }">
      <mat-card-header>
        <mat-card-title>
          Vitals
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-template #noVitals>No vitals added yet</ng-template>
        <clr-timeline
          [clrLayout]="'vertical'"
          *ngIf="currentPatientForm.controls.vitals.value.length; else noVitals"
        >
          <clr-timeline-step
            *ngFor="let vital of currentPatientForm.controls.vitals.value"
          >
            <clr-timeline-step-title>
              {{ vital.date }}
            </clr-timeline-step-title>
            <clr-timeline-step-description>
              <div><b>Temperature:</b> {{ vital.temperature }}</div>
              <div><b>BP Pulse:</b> {{ vital.bpPulse }}</div>
              <div><b>SP02:</b> {{ vital.SP02 }}</div>
              <div><b>Weight:</b> {{ vital.weight }}</div>
              <div><b>Height:</b> {{ vital.height }}</div>
              <div><b>BMI:</b>{{ vital.BMI }}</div>
            </clr-timeline-step-description>
          </clr-timeline-step>
        </clr-timeline>
      </mat-card-content>
      <mat-card-footer>
        <button mat-button color="primary" (click)="addVital()">
          Add Vitals
        </button>
      </mat-card-footer>
    </mat-card>
  </mat-grid-tile>
  <!-- Complaints -->
  <mat-grid-tile [colspan]="1" [rowspan]="1" [ngStyle]="{ overflow: 'scroll' }">
    <mat-card [ngStyle]="{ 'border-radius': '0' }">
      <mat-card-header>
        <mat-card-title>
          Complaints
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-template #noComplaints>No Complaints added yet</ng-template>
        <clr-timeline
          [clrLayout]="'vertical'"
          *ngIf="
            currentPatientForm.controls.complaints.value.length;
            else noComplaints
          "
        >
          <clr-timeline-step
            *ngFor="
              let complaint of currentPatientForm.controls.complaints.value
            "
          >
            <clr-timeline-step-title>
              {{ complaint.date }}
            </clr-timeline-step-title>
            <clr-timeline-step-description>
              <div><b>Description:</b> {{ complaint.description }}</div>
              <div><b>Treatment:</b> {{ complaint.treatment }}</div>
            </clr-timeline-step-description>
          </clr-timeline-step>
        </clr-timeline>
      </mat-card-content>
      <mat-card-footer>
        <button mat-button color="primary" (click)="addComplaint()">
          Add Complaint
        </button>
      </mat-card-footer>
    </mat-card>
  </mat-grid-tile>
  <!-- Investigations -->
  <mat-grid-tile [colspan]="1" [rowspan]="1" [ngStyle]="{ overflow: 'scroll' }">
    <mat-card [ngStyle]="{ 'border-radius': '0' }">
      <mat-card-header>
        <mat-card-title>
          Investigations
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-template #noInvestigations>No Investigations added yet</ng-template>
        <clr-timeline
          [clrLayout]="'vertical'"
          *ngIf="
            currentPatientForm.controls.investigations.value.length;
            else noInvestigations
          "
        >
          <clr-timeline-step
            *ngFor="
              let investigation of currentPatientForm.controls.investigations
                .value
            "
          >
            <clr-timeline-step-title>
              {{ investigation.date }}
            </clr-timeline-step-title>
            <clr-timeline-step-description>
              <div><b>Blood Results:</b> {{ investigation.bloodResults }}</div>
              <div><b>X-Ray Scan:</b> {{ investigation.xrayScan }}</div>
            </clr-timeline-step-description>
          </clr-timeline-step>
        </clr-timeline>
      </mat-card-content>
      <mat-card-footer>
        <button mat-button color="primary" (click)="addInvestigation()">
          Add Investigation
        </button>
      </mat-card-footer>
    </mat-card>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="1" [rowspan]="4" [ngStyle]="{ overflow: 'scroll' }">
    <!--appointments -->
    <mat-card [ngStyle]="{ 'border-radius': '0' }">
      <mat-card-header>
        <mat-card-title>Appointments</mat-card-title>
        <mat-card-subtitle>
          <h3>
            {{ viewDate | calendarDate: view + "ViewTitle":"en" }}
          </h3>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div class="button-row">
          <button
            mat-raised-button
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
          >
            Previous
          </button>
          <button mat-raised-button mwlCalendarToday [(viewDate)]="viewDate">
            Today
          </button>
          <button
            mat-raised-button
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
          >
            Next
          </button>
        </div>
        <br />
        <mwl-calendar-month-view
          (dayClicked)="dayClicked($event.day)"
          [viewDate]="viewDate"
          [events]="events"
        >
        </mwl-calendar-month-view>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>
